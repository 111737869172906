import React from 'react';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/about.scss';

const AboutPage = ({ data }) => (
    <Layout>
        <SEO title="about" />
        <div className="headerText">
            about
        </div>
        <div className="about-content">
            <h3>INDEFINITE HIATUS</h3>
            <p>
                after a few years of operating spring city coffee alongside my existing responsibilities as a husband, dad, and a full-time job, I've decided to push pause. I hope to see you again in a few years.
            </p>
            <p className="signature">
                ~ Garrett DeMeyer
                <br/>
                &nbsp;&nbsp;&nbsp;Owner
            </p>
        </div>
    </Layout>
);

export default AboutPage;